<template>
  <div>
    <molecules-custom-dialog
      v-if="showRemove"
      :show="showRemove"
      title="Apakah Anda Yakin?"
      :caption="`Untuk konfirmasi, masukkan email dari pekerja yang akan dihapus (${showRemove.email})`"
      :items="[
        {
          title: 'Batalkan',
          full: false,
          exec: () => (showRemove = null),
        },
        {
          title: 'Iya Hapus',
          override: 'red--text',
          full: false,
          exec: () => {
            removeWorker()
          },
        },
      ]"
    >
      <atoms-text-field
        color="background"
        label="Tulis kembali email pekerja..."
        :noLabel="true"
        v-model="removeValidator"
    /></molecules-custom-dialog>
    <v-row>
      <v-col cols="12" md="4" title="Jumlah Pekerja secara Keseluruhan">
        <molecules-summary-card
          icon="account-multiple"
          :title="`${$store.state.worker.total}<sup title='Penambahan Pekerja secara Keseluruhan'>+${
            ($store.state.worker.currentDay && $store.state.worker.currentDay.total) || 0
          }</sup> Pekerja`"
          :desc="`Sampai Tanggal ${$moment().format('DD MMMM YYYY')}`"
          :loading="reportLoading"
        />
      </v-col>
      <v-col cols="12" md="4">
        <molecules-summary-card
          icon="account-multiple-plus"
          color="green"
          :title="`${($store.state.worker.currentDay && $store.state.worker.currentDay.total) || 0} Terdaftar Hari Ini`"
          :desc="`${$moment().format('DD MMMM YYYY')}`"
          :loading="reportLoading"
        />
      </v-col>
      <v-col cols="12" md="4">
        <molecules-summary-card
          icon="account-clock"
          color="orange"
          :title="`${
            ($store.state.worker.within7Days &&
              $store.state.worker.within7Days.map((x) => x.total).reduce((x, y) => x + y, 0)) ||
            0
          } Pekerja Terdaftar`"
          :desc="`Dalam 7 Hari Terakhir`"
          :loading="reportLoading"
        />
      </v-col>
    </v-row>

    <v-form @submit.prevent="loadData" id="filter" class="mt-4">
      <v-row justify="space-between">
        <v-col cols="12" md="auto">
          <atoms-button
            class="primary"
            style="width: 100%"
            @click="
              () => {
                sort = sort === constant[0] ? constant[1] : constant[0]
                getWorkers()
              }
            "
            ><v-icon left>mdi-filter-variant-{{ sort === constant[0] ? 'minus' : 'plus' }}</v-icon
            >Sort Tanggal Daftar : {{ sort }}</atoms-button
          >
        </v-col>
        <v-col cols="12" md="auto">
          <v-combobox
            class="rounded-lg"
            placeholder="Filters"
            prepend-inner-icon="mdi-filter-variant"
            :items="filters.map((x) => x.title)"
            v-model="filter"
            @change="loadData"
            hide-details
            multiple
            dense
            solo
            flat
            required
            :style="{
              'box-shadow': 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px',
            }"
        /></v-col>
      </v-row>
    </v-form>

    <div class="mt-4">
      <v-row>
        <v-col cols="12" md="3">
          <v-card class="py-2 px-4" elevation="0">
            <h1 class="text-h5 pt-3 pb-1 text-left text--primary font-weight-medium">Cari Pekerja</h1>
            <p>Ditemukan : {{ $store.state.worker.count }} pekerja</p>
            <v-form @submit.prevent="loadData" id="filter" class="mt-4">
              <v-text-field
                v-model.trim="searchParams.idNumber"
                v-mask="`####################`"
                label="No. KTP"
                dense
                outlined
                required
              />
              <v-text-field v-model.trim="searchParams.fullName" label="Nama Kandidat" dense outlined required />
              <v-text-field v-model.trim="searchParams.email" label="Email" dense outlined required />
              <v-text-field v-model.trim="searchParams.phone" label="Nomor Telepon" dense outlined required />
              <v-select
                v-model.trim="searchParams.verifyAccount"
                :items="['All', 'Sudah', 'Belum']"
                :menu-props="{ offsetY: true }"
                label="Status Verifikasi Rekening"
                dense
                outlined
                required
              />
              <v-select
                v-model.trim="searchParams.admin"
                :items="['All', 'Sudah', 'Belum']"
                :menu-props="{ offsetY: true }"
                label="Sudah Bayar Admin"
                dense
                outlined
                required
              />
              <v-select
                v-model.trim="searchParams.approved"
                :items="['Semua Status', 'Belum diproses', 'Approved', 'Rejected']"
                :menu-props="{ offsetY: true }"
                label="Status Approval"
                dense
                outlined
              />
              <v-select
                v-model.trim="searchParams.sex"
                :items="require('@/constant/genders.js').default"
                :menu-props="{ offsetY: true }"
                label="Jenis Kelamin"
                item-text="label"
                item-value="value"
                dense
                outlined
                @keydown.delete="searchParams.sex = ''"
              />
              <v-select
                v-model.trim="searchParams.statusMarried"
                :items="require('@/constant/statusMarried.type.js').default"
                :menu-props="{ offsetY: true }"
                label="Status Pernikahan"
                item-text="label"
                item-value="value"
                dense
                outlined
                @keydown.delete="searchParams.statusMarried = ''"
              />
              <v-select
                v-model.trim="searchParams.province"
                :items="require('@/constant/provinces.js').default"
                :menu-props="{ offsetY: true }"
                label="Domisili Provinsi"
                item-text="label"
                item-value="value"
                dense
                outlined
                @keydown.delete="
                  () => {
                    searchParams.province = ''
                    searchParams.city = ''
                  }
                "
              />
              <v-select
                v-model.trim="searchParams.city"
                :items="require('@/constant/cities.js').default[searchParams.province] || []"
                :menu-props="{ offsetY: true }"
                label="Domisili Kota"
                item-text="label"
                item-value="value"
                dense
                outlined
                @keydown.delete="searchParams.city = ''"
              />
              <v-text-field v-model.trim="searchParams.kecamatan" label="Kecamatan" dense outlined required />
              <v-text-field v-model.trim="searchParams.kelurahan" label="Kelurahan" dense outlined required />
              <v-text-field v-model.trim="searchParams.streetAddress" label="Alamat" dense outlined required />
              <v-select
                v-model.trim="searchParams.prefProvince"
                :items="require('@/constant/provinces.js').default"
                :menu-props="{ offsetY: true }"
                label="Preferensi Provinsi"
                item-text="label"
                item-value="value"
                dense
                outlined
                @keydown.delete="
                  () => {
                    searchParams.prefProvince = ''
                    searchParams.prefCity = ''
                  }
                "
              />
              <v-select
                v-model.trim="searchParams.prefCity"
                :items="require('@/constant/cities.js')[searchParams.prefProvince] || []"
                :menu-props="{ offsetY: true }"
                label="Preferensi Kota"
                item-text="label"
                item-value="value"
                dense
                outlined
                @keydown.delete="searchParams.prefCity = ''"
              />
              <v-text-field v-model.trim="searchParams.prefJob" label="Preferensi Pekerjaan" dense outlined />
              <v-select
                v-model.trim="searchParams.hadSkill"
                :items="['Ada Keterampilan', 'Belum Ada Keterampilan']"
                :menu-props="{ offsetY: true }"
                label="Keterampilan"
                dense
                outlined
                @keydown.delete="
                  () => {
                    searchParams.hadSkill = ''
                  }
                "
              />
              <v-text-field
                v-if="searchParams.hadSkill === 'Ada Keterampilan'"
                v-model.trim="searchParams.skill"
                label="Keterampilan yang Dicari"
                dense
                outlined
              />
              <v-select
                v-model.trim="searchParams.hadExperience"
                :items="['Ada Pengalaman', 'Belum Ada Pengalaman']"
                :menu-props="{ offsetY: true }"
                label="Pengalaman Bekerja"
                dense
                outlined
                @keydown.delete="
                  () => {
                    searchParams.hadExperience = ''
                  }
                "
              />
              <v-text-field
                v-if="searchParams.hadExperience === 'Ada Pengalaman'"
                v-model.trim="searchParams.experience"
                label="Pengalaman yang Dicari"
                dense
                outlined
              />
              <v-select
                v-model.trim="searchParams.education"
                :items="require('@/constant/educations.js').default"
                :menu-props="{ offsetY: true }"
                label="Pendidikan Terakhir"
                item-text="label"
                item-value="value"
                dense
                outlined
                @keydown.delete="
                  () => {
                    searchParams.education = ''
                  }
                "
              />
              <v-text-field v-model="searchParams.minSalary" label="Minimum Gaji" type="number" dense outlined />
              <v-text-field v-model="searchParams.maxSalary" label="Maksimum Gaji" type="number" dense outlined />
              <v-select
                v-model.trim="searchParams.workerReferral"
                :items="workerReferral || []"
                :item-text="(item) => item.title"
                :item-value="(item) => item"
                :loading="referralLoading"
                :menu-props="{ offsetY: true }"
                label="Referral"
                dense
                outlined
                @keydown.delete="
                  () => {
                    searchParams.workerReferral = ''
                  }
                "
              />
              <v-switch v-model="searchParams.agreeTermPhl" class="mt-0" label="Tunjukkan PHL Saja" dense></v-switch>
              <v-switch
                v-model="searchParams.isHasUniform"
                class="mt-0"
                label="Tunjukkan Yang Memiliki Seragam"
                dense
              ></v-switch>
              <v-switch
                v-model="searchParams.blacklist"
                class="mt-0"
                label="Tunjukkan Pekerja Blacklist"
                dense
              ></v-switch>

              <div class="d-flex align-center mt-n4">
                <v-checkbox v-model="enableDate" hide-spin-buttons /><atoms-text
                  >Filter dengan tanggal (daftar pekerja)</atoms-text
                >
              </div>

              <!-- date -->
              <v-scroll-y-transition>
                <v-col class="mb-5" cols="12" v-if="enableDate">
                  <v-row dense>
                    <v-col cols="12">
                      <v-dialog
                        ref="pickerFromDialog"
                        v-model="from.modal"
                        :return-value.sync="from.value"
                        width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <atoms-text-field label="Dari Tanggal">
                            <v-text-field
                              :loading="mainLoading"
                              class="rounded-lg"
                              placeholder="Dari Tanggal"
                              append-icon="mdi-calendar"
                              background-color="accent"
                              v-model="from.value"
                              v-bind="attrs"
                              v-on="on"
                              hide-details
                              readonly
                              dense
                              solo
                              flat
                              full-width
                              :style="{
                                'box-shadow': 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px',
                              }"
                            />
                          </atoms-text-field>
                        </template>
                        <v-date-picker
                          :max="$moment().endOf('month').format('YYYY-MM-DD')"
                          v-model="from.value"
                          scrollable
                          class="rounded-lg"
                          @click:date="$refs.pickerFromDialog.save(from.value)"
                        />
                      </v-dialog>
                    </v-col>
                    <v-col cols="12">
                      <v-dialog ref="pickerToDialog" v-model="to.modal" :return-value.sync="to.value" width="290px">
                        <template v-slot:activator="{ on, attrs }">
                          <atoms-text-field label="Sampai Tanggal">
                            <v-text-field
                              class="rounded-lg"
                              placeholder="Sampai Tanggal"
                              append-icon="mdi-calendar"
                              background-color="accent"
                              v-model="to.value"
                              :loading="mainLoading"
                              v-bind="attrs"
                              v-on="on"
                              hide-details
                              dense
                              readonly
                              solo
                              flat
                              full-width
                              :style="{
                                'box-shadow': 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px',
                              }"
                            />
                          </atoms-text-field>
                        </template>
                        <v-date-picker
                          :max="$moment().endOf('month').format('YYYY-MM-DD')"
                          v-model="to.value"
                          scrollable
                          class="rounded-lg"
                          @click:date="$refs.pickerToDialog.save(to.value)"
                        />
                      </v-dialog>
                    </v-col>
                  </v-row>
                </v-col>
              </v-scroll-y-transition>
              <atoms-button type="submit" style="width: 100%" class="primary lighten-5 primary--text"
                ><v-icon left>mdi-magnify</v-icon>Cari Pekerja</atoms-button
              >
              <atoms-button
                type="submit"
                style="width: 100%"
                class="teal lighten-5 teal--text text--darken-2 my-3"
                @click.prevent="exportXlsx"
                ><v-icon left>mdi-google-spreadsheet</v-icon>Export XLSX
                <span v-if="loadingXlsxPercentage">{{ loadingXlsxPercentage }}%</span></atoms-button
              >
            </v-form>
          </v-card>
        </v-col>
        <v-col cols="12" md="9">
          <div v-if="mainLoading">
            <v-skeleton-loader
              v-for="i in 5"
              :key="i"
              type="list-item-avatar"
              class="accent rounded-lg mb-2 pa-2"
              :style="{
                'box-shadow': 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px',
              }"
            />
          </div>
          <div v-else>
            <div v-if="$store.state.worker.data && $store.state.worker.data.length > 0">
              <!-- <atoms-text class="pa-2"
                ><v-icon>mdi-magnify</v-icon>Hasil Pencarian, {{ items.count }} data
                ditemukan</atoms-text
              > -->
              <div
                v-for="({ ...rest }, i) in $store.state.worker.data || []"
                :key="i"
                :class="['accent', 'rounded-lg pa-4 mb-2']"
                :style="{
                  'box-shadow': 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px',
                }"
              >
                <v-row align="center">
                  <v-col class="shrink">
                    <v-avatar color="grey lighten-4 rounded-circle">
                      <atoms-image :enableZoom="true" v-if="rest.photo" :src="rest.photo" />
                      <v-icon light v-else>mdi-account</v-icon></v-avatar
                    >
                  </v-col>
                  <v-col cols="8" md="4">
                    <atoms-text :auto="true" class="font-weight-bold">{{
                      $toCapitalize((rest.fullName && rest.fullName) || 'Nama tidak dicantumkan')
                    }}</atoms-text>
                    <atoms-text class="primary--text">{{
                      (rest && rest.email) || 'pekerja.email@website.com'
                    }}</atoms-text>
                  </v-col>
                  <v-col align="start">
                    <atoms-text class="font-weight-bold"
                      >Pekerja sejak {{ $moment(rest._createdDate).format('dddd, DD MMMM YYYY') }}</atoms-text
                    >
                    <atoms-text class="primary--text"
                      >Akun diperbarui {{ $moment(rest && rest._updatedDate).format('dddd, DD MMMM YYYY') }}</atoms-text
                    >
                  </v-col>
                  <v-col align="center" v-if="rest.captain">
                    <v-chip small class="rounded-lg" color="primary">Kapten</v-chip>
                  </v-col>
                  <v-col class="shrink">
                    <v-menu offset-x class="rounded-xl">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn depressed icon v-bind="attrs" v-on="on"><v-icon>mdi-dots-vertical</v-icon></v-btn>
                      </template>
                      <v-list nav dense elevation="0" class="text-center rounded-lg">
                        <v-list-item
                          link
                          @click="showRemove = ($store.state.user._id !== rest._id && rest) || null"
                          class="rounded-lg px-4 text-center"
                          :disabled="
                            $store.state.user.email === rest.email ||
                            !$store.state.user.roles.includes($roles.Super_Admin)
                          "
                        >
                          <v-icon left>mdi-delete-sweep</v-icon>
                          <v-list-item-title class="px-2">Hapus</v-list-item-title>
                        </v-list-item>
                        <v-list-item link @click="showDetail = rest" class="rounded-lg px-4 text-center">
                          <v-icon left>mdi-account-details</v-icon>
                          <v-list-item-title class="px-2">Detail</v-list-item-title>
                        </v-list-item>
                        <v-list-item link @click="showEdit = rest" class="rounded-lg px-4 text-center">
                          <v-icon left>mdi-account-edit</v-icon>
                          <v-list-item-title class="px-2">Edit</v-list-item-title>
                        </v-list-item>
                        <v-list-item
                          :disabled="rest.captain"
                          link
                          @click="showAssign = rest"
                          class="rounded-lg px-4 text-center"
                        >
                          <v-icon left>mdi-account-tie-hat</v-icon>
                          <v-list-item-title class="px-2">Jadikan Kapten</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-col>
                </v-row>
                <br />
                <atoms-container class="px-0 pb-0">
                  <atoms-text
                    ><v-icon left small>mdi-map-marker</v-icon>
                    {{ rest.address && rest.address.streetAddress && rest.address.streetAddress + ',' }}
                    {{ rest.address && rest.address.city && rest.address.city + '-' }}
                    {{ (rest.address && rest.address.province) || 'Provinsi Tidak Dicantumkan' }}</atoms-text
                  >
                  <atoms-text><v-icon left small>mdi-phone</v-icon> {{ rest.phone || '-' }}</atoms-text>
                  <atoms-text v-if="rest.isHasUniform"
                    ><v-icon left small>mdi-tshirt-crew</v-icon> Riwayat Kepemilikan Seragam :
                    {{
                      (rest.uniformPurchaseDate && $moment(rest.uniformPurchaseDate).format('DD MMMM YYYY')) || '-'
                    }}</atoms-text
                  >
                </atoms-container>
              </div>
              <v-row justify="center">
                <v-col cols="8">
                  <v-container class="max-width">
                    <v-pagination class="my-4" v-model="page" :length="$store.state.worker.pagesLength"></v-pagination>
                  </v-container>
                </v-col>
              </v-row>
            </div>
            <molecules-wrapper v-else class="d-flex justify-center align-center my-8 py-8 outlined">
              <div class="text-center">
                <atoms-image :width="250" src="/assets/missing.svg" />
                <atoms-title :h3="true" class="mt-6">Tidak ada pekerja yang ditemukan!</atoms-title>
              </div>
            </molecules-wrapper>
          </div>
        </v-col>
      </v-row>
    </div>
    <!-- detail -->
    <v-slide-x-reverse-transition>
      <organism-workers-edit
        v-if="showDetail"
        :data="showDetail"
        @closed="
          () => {
            showDetail = null
          }
        "
      />
    </v-slide-x-reverse-transition>
    <v-slide-x-reverse-transition>
      <organism-workers-edit
        v-if="showEdit"
        :data="showEdit"
        :pureEdit="true"
        @closed="
          () => {
            showEdit = null
          }
        "
      />
    </v-slide-x-reverse-transition>
    <v-slide-x-reverse-transition>
      <organism-captains-register
        v-if="showAssign"
        :assign="showAssign"
        @closed="
          () => {
            showAssign = null
          }
        "
      />
    </v-slide-x-reverse-transition>
  </div>
</template>

<script>
export default {
  data() {
    return {
      page: 1,
      limit: 10,
      sort: '',
      removeValidator: '',
      showRemove: null,
      showDetail: null,
      showAssign: null,
      showEdit: null,
      constant: ['Desc', 'Asc'],

      workerReferral: null,
      referralLoading: false,
      reportLoading: false,
      mainLoading: false,
      filter: null,
      filters: [
        {
          title: 'Captain',
          value: {
            captain: true,
          },
        },
        {
          title: this.$status.belum,
          value: {
            active: { $ne: true },
          },
        },
        {
          title: this.$status.approve,
          value: {
            approved: { $eq: true },
          },
        },
        {
          title: this.$status.reject,
          value: {
            approved: { $eq: false },
          },
        },
        {
          title: this.$status.semua,
          value: {
            approved: undefined,
            active: undefined,
          },
        },
      ],
      searchParams: {},
      loadingXlsxPercentage: 0,
      enableDate: false,
      from: {
        modal: false,
        value: this.$moment().startOf('month').format('YYYY-MM-DD'),
      },
      to: {
        modal: false,
        value: this.$moment().format('YYYY-MM-DD'),
      },
    }
  },
  watch: {
    page: {
      handler() {
        this.getSummary()
        this.getWorkers()
      },
      deep: true,
    },
  },
  async mounted() {
    this.sort = this.constant[0]
    this.loadData()
  },
  methods: {
    loadData() {
      this.page = 1
      this.getWorkers()
      this.getSummary()
      this.getReferral()
    },
    async getReferral() {
      this.referralLoading = true
      try {
        this.workerReferral = (await this.$api.get('WorkerReferrals/get'))?.result
      } catch (error) {
        alert(err?.response?.data?.message || err?.message)
      } finally {
        this.referralLoading = false
      }
    },
    async getSummary() {
      this.reportLoading = true
      try {
        await this.$store.dispatch('worker/summary')
      } catch (error) {
        alert(err?.response?.data?.message || err?.message)
      } finally {
        this.reportLoading = false
      }
    },
    getFilter() {
      // searchParams
      const fullName = this.searchParams.fullName
      const email = this.searchParams.email
      const phone = this.searchParams.phone
      const idNumber = this.searchParams.idNumber
      const verifyAccount = this.searchParams.verifyAccount
      const admin = this.searchParams.admin
      const approved = this.searchParams.approved
      // additional searchParams
      const sex = this.searchParams.sex
      const statusMarried = this.searchParams.statusMarried
      const province = this.searchParams.province
      const city = this.searchParams.city
      const kecamatan = this.searchParams.kecamatan
      const kelurahan = this.searchParams.kelurahan
      const streetAddress = this.searchParams.streetAddress
      const prefProvince = this.searchParams.prefProvince
      const prefCity = this.searchParams.prefCity
      const prefJob = this.searchParams.prefJob
      const hadSkill = this.searchParams.hadSkill
      const skill = this.searchParams.skill
      const hadExperience = this.searchParams.hadExperience
      const experience = this.searchParams.experience
      const education = this.searchParams.education
      const minSalary = this.searchParams.minSalary
      const maxSalary = this.searchParams.maxSalary
      const agreeTermPhl = this.searchParams.agreeTermPhl
      const blacklist = this.searchParams.blacklist
      const workerReferral = this.searchParams.workerReferral

      // main query
      const queryMain = {}

      if (this.enableDate) {
        queryMain._createdDate = {
          $gte: new Date(new Date(this.from.value).setHours(0, 0, 0, 0)),
          $lt: new Date(new Date(this.to.value).setHours(45, 0, 0, 0)),
        }
      }
      if (fullName) {
        queryMain.fullName = { $regex: fullName || '', $options: 'i' }
      }
      if (email) {
        queryMain.email = { $regex: email || '', $options: 'i' }
      }
      if (phone) {
        queryMain.phone = { $regex: phone || '', $options: 'i' }
      }
      // query detail
      const queryDetail = {}
      // initialize pipeline
      const pipeline = [
        {
          $lookup: {
            from: 'WorkerDetails',
            localField: 'detailId',
            foreignField: '_id',
            as: 'detail',
          },
        },
        {
          $unwind: {
            path: '$detail',
            preserveNullAndEmptyArrays: true,
          },
        },
        {
          $lookup: {
            from: 'WorkerReferrals',
            localField: 'workerReferral',
            foreignField: '_id',
            as: 'workerReferralData',
          },
        },
        {
          $unwind: {
            path: '$workerReferralData',
            preserveNullAndEmptyArrays: true,
          },
        }
      ]
      // // _id from params
      // if (this.workerId) {
      //   queryMain._id = this.workerId;
      //   this.workerId = "";
      // }
      // // workerIds from params
      // if (this.strWorkerIds) {
      //   const workerIds = this.strWorkerIds.split(",");
      //   queryMain._id = { $in: workerIds };
      //   this.strWorkerIds = "";
      // }
      // idNumber
      if (idNumber) {
        queryMain.idNumber = idNumber
      }
      // Status Approval
      switch (approved) {
        case 'Belum diproses':
          queryMain.active = { $ne: true }
          queryMain.approved = { $ne: false }
          break
        case 'Approved':
          queryMain.approved = { $eq: true }
          break
        case 'Rejected':
          queryMain.approved = { $eq: false }
          break
        default:
          break
      }
      // PHL Rekening Verifikasi
      if (verifyAccount === 'Sudah') {
        queryMain.verified = true
      } else if (verifyAccount === 'Belum') {
        queryMain.verified = { $ne: true }
      } else {
        queryMain.verified = undefined
      }
      // Bayar PHL Admin
      if (admin === 'Sudah') {
        queryMain.adminPayVerified = true
      } else if (admin === 'Belum') {
        queryMain.adminPayVerified = { $ne: true }
      } else {
        queryMain.adminPayVerified = undefined
      }
      // gender
      if (sex) {
        queryMain.sex = sex
      }
      // marital status
      if (statusMarried) {
        queryMain.statusMarried = statusMarried
      }
      // Domicile
      if (province) {
        queryMain['address.province'] = province
      }
      if (city) {
        queryMain['address.city'] = city
      }
      if (kecamatan) {
        queryMain['address.kecamatan'] = {
          $regex: kecamatan || '',
          $options: 'i',
        }
      }
      if (kelurahan) {
        queryMain['address.kelurahan'] = {
          $regex: kelurahan || '',
          $options: 'i',
        }
      }
      if (streetAddress) {
        queryMain['address.streetAddress'] = {
          $regex: streetAddress || '',
          $options: 'i',
        }
      }
      // Preference Place
      if (prefProvince || prefCity) {
        queryDetail['detail.prefCities'] = {
          $elemMatch: {},
        }
      }
      if (prefProvince) {
        queryDetail['detail.prefCities'].$elemMatch.province = prefProvince
      }
      if (prefCity) {
        queryDetail['detail.prefCities'].$elemMatch.city = prefCity
      }
      // Preference Job
      if (prefJob) {
        queryDetail['detail.prefJobs'] = {
          $elemMatch: { $regex: prefJob || '', $options: 'i' },
        }
      }
      // skill
      if (hadSkill === 'Belum Ada Keterampilan') {
        queryDetail['detail.skills.0'] = { $exists: false }
      } else if (skill) {
        queryDetail['detail.skills'] = {
          $elemMatch: { name: { $regex: skill || '', $options: 'i' } },
        }
      }
      // hadExperience
      if (hadExperience === 'Belum Ada Pengalaman') {
        queryDetail['detail.experiences.0'] = { $exists: false };
      } else if (experience) {
        queryDetail['detail.experiences'] = {
          $elemMatch: { name: { $regex: experience || '', $options: 'i' } },
        }
      }
      // pendidikan terakhir
      if (education) {
        queryMain.education = education
      }
      // gaji
      if (minSalary || maxSalary) {
        queryMain.salary = {
          $gte: +minSalary || 0,
          $lte: +maxSalary || Number.MAX_VALUE,
        }
      }
      // Agree Term PHL
      if (agreeTermPhl) {
        queryMain.agreeTermPhl = agreeTermPhl
      }
      // Blacklist
      if (blacklist) {
        queryMain.blacklist = blacklist
      }

      if (workerReferral) {
        queryMain.workerReferral = workerReferral._id
      }

      if (this.searchParams.isHasUniform) {
        queryMain.isHasUniform = this.searchParams.isHasUniform
      }

      const matchQuery = {
          $match: {
              $and: Object.entries(Object.assign({}, queryMain, queryDetail)).map(([k, v]) => ({ [k]: v }))
          },
      }

      pipeline.push(matchQuery)
      
      const filter = {
        ...(this.filter
          ? Object.assign({}, ...this.filter?.map((x) => this.filters?.find((y) => y.title === x)?.value))
          : []),
        pipeline: [
          {
            $sort: {
              _createdDate: this.sort === this.constant[0] ? -1 : 1,
            },
          },
          ...pipeline,
        ],
      }
      return filter
    },
    async getWorkers() {
      this.mainLoading = true
      try {
        const filter = this.getFilter()
        //* fetch worker data
        await this.$store.dispatch('worker/getAll', {
          custom: filter,
          page: this.page,
          limit: this.limit,
        })
      } catch (error) {
        alert(err?.response?.data?.message || err?.message)
      } finally {
        this.mainLoading = false
      }
    },
    async removeWorker() {
      this.$store.commit('SET', { loading: true })
      const email = this.showRemove?.email
      if (this.showRemove?._id && email == this.removeValidator) {
        await this.$store
          .dispatch('worker/remove', this.showRemove?._id)
          .then((x) => {
            this.$showDialog({
              title: 'Berhasil',
              body: `${this.showRemove?.fullName || 'Pekerja'} berhasil dihapus`,
            })
            this.showRemove = null
            this.removeValidator = ''
            this.loadData()
          })
          .catch((x) => {
            this.$showDialog({
              title: 'Gagal',
              body: x,
            })
          })
      } else {
        this.$showDialog({
          title: 'Kesalahan',
          body: 'Email yang anda masukkan tidak sesuai dengan email user yang akan dihapus!',
        })
      }
      this.$store.commit('SET', { loading: false })
    },
    async exportXlsx() {
      const isConfirmed = confirm('Yakin mau ekspor?')
      if (!isConfirmed) return
      this.$store.commit('SET', { loading: true })
      this.loadingXlsxPercentage = 0
      try {
        const filter = this.getFilter()
        // const realm = await this.$realm()
        // const workers = await realm.functions.getAllWorkers(JSON.stringify(filter))
        const workers = await this.$api
          .get("Workers" + '/getMongo', {
            params: {
              jsonQuery: JSON.stringify(filter),
            },
          })
          .then(async ({ result }) => {
            return result;
          })
        const columns = [
          {
            label: 'Nama Pekerja',
            value: 'Nama Pekerja',
          },
          {
            label: 'No KTP',
            value: 'No KTP',
          },
          {
            label: 'Email',
            value: 'Email',
          },
          {
            label: 'No Telepon',
            value: 'No Telepon',
          },
          {
            label: 'Tempat Lahir',
            value: 'Tempat Lahir',
          },
          {
            label: 'Tanggal Lahir',
            value: 'Tanggal Lahir',
          },
          {
            label: 'Alamat',
            value: 'Alamat',
          },
          {
            label: 'No Rekening',
            value: 'No Rekening',
          },
          {
            label: 'Nama Rekening',
            value: 'Nama Rekening',
          },
          {
            label: 'Tipe Rekening',
            value: 'Tipe Rekening',
          },
          {
            label: 'Verifikasi Rekening',
            value: 'Verifikasi Rekening',
          },
          {
            label: 'Sudah Bayar Admin',
            value: 'Sudah Bayar Admin',
          },
          {
            label: 'NPWP',
            value: 'NPWP',
          },
          {
            label: 'Tanggal Update',
            value: 'Tanggal Update',
          },
          {
            label: 'Tanggal Daftar',
            value: 'Tanggal Daftar',
          },
          {
            label: 'Referral',
            value: 'Referral',
          },
        ]
        const content = workers.map((item) => {
          return {
            'Nama Pekerja': item.fullName,
            'No KTP': item.idNumber,
            Email: item.email,
            'No Telepon': item.phone,
            'Tempat Lahir': item.birthplace,
            'Tanggal Lahir': item.birthday
              ? this.$moment(item.birthday).utcOffset('+0700').locale('id').format('DD/MM/YYYY')
              : undefined,
            Alamat: item?.address?.streetAddress,
            'No Rekening': item?.accountDetails?.accountNumber,
            'Nama Rekening': item?.accountDetails?.accountName,
            'Tipe Rekening': item?.accountDetails?.bankName,
            'Verifikasi Rekening': item?.verified ? 'Sudah Terverifikasi' : 'Belum',
            'Sudah Bayar Admin': item?.adminPayVerified ? 'Sudah' : 'Belum',
            NPWP: item.npwp,
            'Tanggal Update': item._updatedDate
              ? this.$moment(item._updatedDate).utcOffset('+0700').locale('id').format('DD-MM-YYYY HH:mm')
              : undefined,
            'Tanggal Daftar': item._createdDate
              ? this.$moment(item._createdDate).utcOffset('+0700').locale('id').format('DD-MM-YYYY HH:mm')
              : undefined,
            Referral: item?.workerReferralData?.title,
          }
        })
        let data = [
          {
            sheet: 'Workers',
            columns,
            content,
          },
        ]
        let settings = {
          fileName: 'Database Pekerja',
        }
        this.$xlsx(data, settings)
      } catch (err) {
        alert(err?.response?.data?.message || err?.message)
      }
      this.$store.commit('SET', { loading: false })
    },
  },
}
</script>
