export default [
  {
    label: 'Laki-laki',
    value: 'Male',
  },
  {
    label: 'Perempuan',
    value: 'Female',
  },
]
