export default [
  {
    label: 'Tidak Sekolah',
    value: 'Tidak Sekolah',
  },
  {
    label: 'TK',
    value: 'TK',
  },
  {
    label: 'SD',
    value: 'SD',
  },
  {
    label: 'SMP',
    value: 'SMP',
  },
  {
    label: 'SMA / SMK',
    value: 'SMA / SMK',
  },
  {
    label: 'D1',
    value: 'D1',
  },
  {
    label: 'D2',
    value: 'D2',
  },
  {
    label: 'D3',
    value: 'D3',
  },
  {
    label: 'D4',
    value: 'D4',
  },
  {
    label: 'S1',
    value: 'S1',
  },
  {
    label: 'S2',
    value: 'S2',
  },
  {
    label: 'S3',
    value: 'S3',
  },
]
